import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConstantsData, HTTP_403} from '@app/models/constants-data';

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorExceptionService implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Avoid to diplay error for EP when calling users/infos
                if (!window.location.hostname.includes(ConstantsData.INCLUDE_URI_KEYWORD_ASSURE) && error.url.endsWith('/api/users/infos')) {
                    console.log('Prospect non authentifié :  non bloquant');
                } else if (this.checkErrorFromCaptcha(error)) {
                    console.log('Affichage challenge captcha : non bloquant');
                } else if ([400, 401, HTTP_403, 404, 405, 410, 412, 416, 500, 503].some(httpCode => httpCode === error.status)) {
                    this.router.navigate([`/error/${error.status}`]);
                }
                // Throw all exceptions those allow to diplay reject popin, except 401 which must be displayed in page
                return throwError(error);
            })
        );
    }

    /**
     * Method to check if error from captcha challenge
     * @param error : HttpErrorResponse
     */
    checkErrorFromCaptcha(error: HttpErrorResponse): boolean {
        console.log(`error is ${JSON.stringify(error)}`);
        console.log(`error is ${JSON.stringify(error?.error)}`);
        return error.status === HTTP_403 && error?.error?.url?.includes('geo.captcha-delivery.com');
    }
}
