export class ConstantsData {
    public static CXTE_CALLER_ID = 'VENTE-PROTECTIONJURIDIQUE-INTERNET';
    public static QUESTION_TYPE_CHOICES_CHECKBOX = 'choices_checkbox';
    public static QUESTION_TYPE_INFO_MSG = 'info_msg';
    public static INCLUDE_URI_KEYWORD_ASSURE = 'assure';
    public static SVG_AGREMENT_CODE = 'SVG';
    public static PHONE_NUMBER_ATTR = 'phoneNumber';
    public static POSTAL_CODE_ATTR = 'postalCode';
    public static ES_MODE_LIBELLE = 'ES';
    public static EP_MODE_LIBELLE = 'EP';
    public static ACTION_CAPTCHA_LIBELLE = 'TARIF_DEVIS_PROTECTIONJURIDIQUE';
    // Constants path
    public static CONTENT_TYPE = 'Content-type';
    public static APPLICATION_JSON = 'application/json';
    // PDF Path
    public static URL_PDF_GENERAL_CONDITION = 'https://www.gmf.fr/files/live/sites/gmf/files/documents-reglementaires/assurance-protection-juridique/cg-protection-juridique.pdf';
    public static URL_PDF_JURIDIC_PROTECTION = 'https://www.gmf.fr/files/live/sites/gmf/files/documents-reglementaires/assurance-protection-juridique/ipid-protection-juridique.pdf';
    public static URL_ESTIMATE = '/devis';
    public static FORMAT_DATE = 'dd/MM/yyyy';
    public static CONTEXT_ROOT_PRICING = '/devis/tarif';
    public static LIBELLE_PREFIX_FORMULA = 'FORMULE';
    // Restitution Table
    public static TABLE_RECOMMEND_FORMULA_TITLE = 'restitution.table.recommend.formula.title';
    public static TABLE_ESTIMATE_CHOICE_PRICE_TITLE = 'restitution.table.choice.price.title';
    public static TABLE_ESTIMATE_TITLE = 'restitution.table.title';
    public static TABLE_ESTIMATE_DESCRIPTIVE = 'restitution.table.descriptive';

    // Restitution Infos Block
    public static RECAP_INFOS_ALL_WORKS = 'restitution.recap.home.allworks';
    public static RECAP_INFOS_OUTDOOR_WORK = 'restitution.recap.home.outdoorwork';
    public static RECAP_INFOS_CONSTRUCTION_WORK = 'restitution.recap.home.constructionwork';
    public static RECAP_INFOS_NO_WORK = 'restitution.recap.home.nowork';
    public static RECAP_INFOS_NEW_HOUSE = 'restitution.recap.home.newhouse';
    public static RECAP_INFOS_NO_NEW_HOUSE = 'restitution.recap.home.nonewhouse';
    public static RECAP_INFOS_COVERED_EVENT_LITIGATION = 'restitution.recap.covered.event.ligitation';
    public static RECAP_INFOS_NO_COVERED_EVENT_LITIGATION = 'restitution.recap.no.covered.event.ligitation';
    public static RECAP_INFOS_BENIFIT_CONTRACT_GARANTEE = 'restitution.recap.benifit.contract.garantee';
    public static RECAP_INFOS_NO_BENIFIT_CONTRACT_GARANTEE = 'restitution.recap.no.benifit.contract.garantee';

    public static RECAP_INFOS_REAL_ESTATE_RENTAL_NUMBER = 'restitution.recap.real.estate.rental.total.number';
    public static RECAP_INFOS_REAL_ESTATE_RENTAL_ALL = 'restitution.recap.real.estate.rental.all';
    public static RECAP_INFOS_REAL_ESTATE_RENTAL_WORK_RETURN = 'restitution.recap.real.estate.rental.covered.litigation.work.return';
    public static RECAP_INFOS_REAL_ESTATE_RENTAL_THERE_ANY = 'restitution.recap.real.estate.rental.there.any';
    // Situation Price
    public static ESTIMATE_OUTPUT = 'devis';
    public static RECEIVE_EMAIL_OUTPUT = 'recevoirDevis';
    public static SUBSCRIPTION_OUTPUT = 'souscription';
    public static CALL_ADVISER_OUTPUT = 'conseiller';
    public static APPOINTEMENT_AGENCY_OUTPUT = 'agence';
    public static BLOCK_SITUATION_CONTACT_TITLE = 'restitution.block.contact.title';
    public static BLOCK_SITUATION_PRICE_OPTS_TITLE = 'restitution.block.price.opts.title';
    public static BLOCK_SITUATION_PRICE_INFORMATIONS = 'restitution.block.price.information';

    // Reject popin id : it's the one used in co-gmf-cf-popin (see principal div)
    public static REJECT_POP_IN_ID = '#rejectPopin';
    public static REJECT_POPIN_ERROR_RECONSULT_TITLE = 'pop-in.reconsult.error.title';
    public static REJECT_POPIN_ERROR_RECONSULT_PHRASE1 = 'pop-in.reconsult.error.description';
    public static REJECT_POPIN_ERROR_RECONSULT_PHRASE2 = 'pop-in.contact';

    public static REJECT_POPIN_ERROR_SUBSCRIPTION_TITLE = 'pop-in.subscription.error.title';
    public static REJECT_POPIN_INDISPONIBLE_SUBSCRIPTION_TITLE = 'pop-in.subscription.indisponible.title';
    public static REJECT_POPIN_INDISPONIBLE_SUBSCRIPTION_DESCRIPTION = 'pop-in.subscription.indisponible.description';

    public static REJECT_POPIN_ERROR_SUBSCRIPTION_PHRASE1 = 'pop-in.subscription.error.description1';
    public static REJECT_POPIN_ERROR_SUBSCRIPTION_PHRASE2 = 'pop-in.subscription.error.description2';

    public static INFO_POPIN_SUBSCRIPTION_PHRASE = 'pop-in.subscription.phrase';

    // Right Buttons Price Block
    public static LIBELLE_NEW_ESTIMATE_PROCESS = 'Faire une nouvelle demande de tarif';
    public static LIBELLE_SUBSCRIPTION_EN_LIGNE_BTN = 'Souscrire en ligne';
    public static LIBELLE_SUBSCRIPTION_BTN = 'Souscrire';
    public static LIBELL_SEND_EMAIL = 'Recevoir mon devis par email';

    // Left Buttons
    public static TO_BE_CONTACTED = 'Être rappelé par un conseiller';
    public static TO_MAKE_RDV_AGENCY = 'Prendre rendez-vous en agence';

    public static FORMULA_FESO = 'FESO';
    public static FORMULA_FISO = 'FISO';
    public static FORMULA_FIAO = 'FIAO';

    // Tracking constants
    public static NOUVELLE_DEMANDE_TARIF = 'nouvelle_demande_tarif';
    public static EN_SAVOIR_PLUS = 'en_savoir_plus';
    public static RECEVOIR_DEVIS_EMAIL = 'recevoir_devis_email';
    public static SOUSCRIRE_EN_LIGNE = 'souscrire_en_ligne';
    public static ETRE_RAPPELE_PAR_UN_CONSEILLER = 'etre_rappele_par_un_conseiller';
    public static PRENDRE_RENDEZ_VOUS_EN_AGENCE = 'prendre_rendez_vous_en_agence';
    public static JE_ME_CONNECTE_ESPACE_SOCIETAIRE = 'je_me_connecte_espace_societaire';
    public static PAGE_RESULTATS_DEVIS = 'page_resultats_devis';
    public static DEVIS_PROTECTION_JURIDIQUE_PAGE_RESULTATS_DEVIS = 'devis_protection_juridique/page_resultats_devis';
    public static AFFICHAGE_FORMULES = 'affichage_formules';
    public static DEVIS_PROTECTION_JURIDIQUE = 'devis_protection_juridique';
    public static JE_VEUX_VOIR_LE_DETAIL_DE_MES_OFFRES = 'je_veux_voir_le_detail_de_mes_offres';
    public static OUI_JE_SUIS_SOCIETAIRE_GMF = 'oui_je_suis_societaire_gmf';

    public static PAGE_ESTIMATE_TITLE = 'title.page.devis';
    public static PAGE_RESTITUTION_TITLE = 'title.page.restitution';

}

export const ACTION_NAME = {
    CALLBACK: 'demander_a_etre_rappele',
    PHONE: 'gmf_en_ligne',
    APPOINTMENT: 'prendre_rendez_vous_avec_un_conseiller'
};

export const COVEUSE_CONFIG = 'COVEUSE_CONFIG';
export const CONFIG = 'config';

export const HTTP_403 = 403;
