import {Environment} from '@env/environment.interfaces';
import * as merge from 'lodash.merge';

const URL_RDV = 'https://www.gmf.fr/agences-gmf';
let properties: Environment = {
    production: true,
    router: {
        enableTracing: false
    },
    security: {
        endpointUrl: ''
    },
    logCentral: {
        isEnable: true,
        handlerLogUrl: ''
    },
    urlApiBackUsg: '',
    esMode: false,
    urlMemberSpace: '',
    urlMonEspaceSoc: '',
    urlApiBackMember: '',
    urlHomeEs: '',
    urlHomeEp: '',
    urlRdv: URL_RDV,
    urlCallBackEs: 'https://espace-assure.gmf.fr/se-faire-rappeler',
    urlCallBackEp: 'https://contact.gmf.fr/se-faire-rappeler',
    urlCallCustomerAdviserEs: 'https://espace-assure.gmf.fr/pointentree/client/contacts',
    urlCallCustomerAdviserEp: 'https://www.gmf.fr/contacter-gmf-par-telephone',
    urlFindAgencyEs: 'https://espace-assure.gmf.fr/pointentree/rechercheagence',
    urlFindAgencyEp: URL_RDV,
    angularticsTagco: {
        appId: '2392', // required
        jsName: 'tc_GMF_', // required
        eventFunctionName: 'tc_events_1', // required
        eventOnName: 'eventGA',
        prod: true,
        preventHitOnInit: true,
        dataLayer: {
            env_template: 'devis_protection_juridique', // Type de page
            env_work: 'production', // Environnement de travail
            env_marque: 'gmf', // marque appelant la plateforme de service
            env_language: 'fr'
        },
        jsIndexFile1: '3',
        jsIndexFile2: '1',
        jsIndexFile3: '5'
    }
};

export const environment: Environment = merge(properties, window['COVEUSE_CONFIG']);
