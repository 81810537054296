<div *ngIf="isCanAccess" [ngClass]="{'zo-page': true, 'has-sticky-header': environment.isHeaderSticky}">
    <a id="Top"></a>
    <nav aria-label="Accès rapide" class="zo-skip-navigation" role="navigation">
        <div class="zo-wrapper">
            <ul>
                <li>
                    <a (click)="focusTarget($event, 'Content')" href="#Content" title="Accéder directement à la zone principale">Contenu</a>
                </li>
                <li class="is-mobile-tablet-hidden">
                    <a (click)="focusTarget($event, 'NavigationPanel')" href="#NavigationPanel" title="Accéder directement au menu de navigation Desktop">Menu principal</a>
                </li>
                <li class="is-desktop-hidden">
                    <a (click)="focusTarget($event, 'NavigationPanel')" class="js-tp-panel-trigger" href="#NavigationPanel" title="Accéder directement au menu de navigation Mobile">Menu principal</a>
                </li>
            </ul>
        </div>
    </nav>
    <div aggregator-replaced="true" id="{{environment.esMode ? 'HeaderEC' : 'Header'}}"></div>
    <div class="zo-content sg-protection-juridique" id="Content" role="main">
        <router-outlet></router-outlet>
    </div>
</div>
