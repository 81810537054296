import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GmfUiChatFormModule} from '@co/gmf-ui-chat-form';
import {TranslateModule} from '@ngx-translate/core';
import {GmfUiRestitutionModule} from '@co/gmf-ui-restitution';

@NgModule({
    imports: [
        CommonModule,
        GmfUiChatFormModule,
        GmfUiRestitutionModule,
        TranslateModule
    ],
    declarations: [],
    exports: [
        CommonModule,
        GmfUiChatFormModule,
        GmfUiRestitutionModule,
        TranslateModule
    ]
})
export class SharedModule {
}
