import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as merge from 'lodash.merge';
import {COVEUSE_CONFIG} from '@app/models/constants-data';

if (environment.production) {
    enableProdMode();
    const script = document.createElement('script');
    script.onload = bootstrap;
    script.onerror = bootstrap;
    script.src = 'config/config.js';
    document.head.appendChild(script);
} else {
    bootstrap();
}

function bootstrap() {
    if (window[COVEUSE_CONFIG]) {
        merge(environment, window[COVEUSE_CONFIG]);
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}


