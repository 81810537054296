import {Component, ElementRef, ViewContainerRef} from '@angular/core';
import {ToasterService} from '@coveuse/toaster';
import {SecurityService} from '@coveuse/security';
import {environment} from '@env/environment';
import {ConstantsData} from '@app/models/constants-data';
import {UserService} from '@co/gmf-ui-chat-form';
import {Router} from '@angular/router';
import {ConfigEnvironment, ConfigService} from "@co/gmf-ui-common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // URI to exclude in security service
    EXLUDED_URI = [/geo.api.gouv.fr/];
    // Default for EP
    isCanAccess = true;
    environment = environment;

    public constructor(private el: ElementRef, private router: Router, private _toaster: ToasterService, vcr: ViewContainerRef,
                       securityService: SecurityService, private configService: ConfigService,
                       private userService: UserService) {
        this._toaster.setRootViewContainerRef(vcr);
        // It's necessary to call publique external URI as : GEO GOUV,...
        securityService.addExclusions(this.EXLUDED_URI);

        configService.configureEnvironmentUrl({
            urlApiSocietaire: environment.urlApiBackMember,
            urlEspaceSocietaire: environment.urlMemberSpace,
            contextCallerId: ConstantsData.CXTE_CALLER_ID
        } as ConfigEnvironment);

        // Manage Es Mode
        environment.esMode = window.location.hostname.includes(ConstantsData.INCLUDE_URI_KEYWORD_ASSURE);
        if (environment.esMode) {
            this.isCanAccess = securityService.isAuthenticated();
            if (!this.isCanAccess) {
                const url = window.location.href;
                window.location.href = `${environment.urlMonEspaceSoc}?nextUrl=${url}`;
            } else {
                this.userService.getUserData().subscribe((user) => {
                    this.userService.currentUser$.next(user);
                });
            }
        }
    }

    focusTarget(event: Event, target: string): void {
        event.preventDefault();
        location.hash = target;
    }

    getCurrentRoute(): string {
        return this.router.url.split('#')[0];
    }
}
