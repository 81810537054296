import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoGmfCfErrorPageComponent} from '@co/gmf-ui-chat-form';

const routes: Routes = [
    {path: '', redirectTo: '/devis', pathMatch: 'full'},
    {
        path: 'devis', loadChildren: () => import('./estimate/estimate.module').then(m => m.EstimateModule),
    },
    {path: 'error/:errorCode', component: CoGmfCfErrorPageComponent},
    {path: '**', redirectTo: '/error/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {


}
