import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@app/core/core.module';
import {SharedModule} from '@app/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {HttpInterceptorExceptionService} from '@app/services/http-interceptor-exception.service';
import {CONFIG, ConstantsData} from "@app/models/constants-data";
import {environment} from "@env/environment";

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        TranslateModule.forRoot({
                defaultLanguage: 'fr',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        )
    ],
    providers: [
        CookieService,
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorExceptionService, multi: true},
        {
            provide: CONFIG, useValue: {
                urlApiSocietaire: environment.urlApiBackMember,
                urlEspaceSocietaire: environment.urlMemberSpace,
                contextCallerId: ConstantsData.CXTE_CALLER_ID
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
